import React from 'react';
// import sections
import RightHandRuleSection from '../components/sections/RightHandRuleSection';
import Cta from '../components/sections/Cta';

const RightHandRule = () => {

  window.location.href = "https://mapstertech.github.io/mapster-right-hand-rule-fixer/"

  return (
    <>
      <RightHandRuleSection className="illustration-section-01" />
      <Cta split />
    </>
  );
}

export default RightHandRule;
