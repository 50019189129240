import React, { useState } from 'react';
import classNames from 'classnames';
import { SectionProps } from '../../utils/SectionProps';
import ButtonGroup from '../elements/ButtonGroup';
import Button from '../elements/Button';
import Image from '../elements/Image';
import Modal from '../elements/Modal';

import rewind from 'geojson-rewind';
import geojsonhint from '@mapbox/geojsonhint'

const propTypes = {
  ...SectionProps.types
}

const defaultProps = {
  ...SectionProps.defaults
}

const Hero = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  ...props
}) => {

  const [videoModalActive, setVideomodalactive] = useState(false);

  const openModal = (e) => {
    e.preventDefault();
    setVideomodalactive(true);
  }

  const closeModal = (e) => {
    e.preventDefault();
    setVideomodalactive(false);
  }

  const outerClasses = classNames(
    'hero section center-content',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'hero-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const convert = () => {
    var geoJSONToConvert = document.getElementById('converter').value;
    var fixedGeoJSON = false;
    var errorArray = geojsonhint.hint(geoJSONToConvert);
    if(errorArray.length>0&&errorArray[0].message!=='Polygons and MultiPolygons should follow the right-hand rule') {
      alert(errorArray[0].message);
    } else {
      var newGeoJSON = rewind(geoJSONToConvert, true);
      document.getElementById('conversion').value = newGeoJSON;
    }
  }

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container-sm">
        <div className={innerClasses}>
          <div className="hero-content">
            <h1 className="mt-0 mb-16 reveal-from-bottom" data-reveal-delay="200">
              <span className="text-color-primary">Right Hand Rule</span> GeoJSON Fixer.
            </h1>
            <div className="container-xs">
              <p className="m-0 mb-32 reveal-from-bottom" style={{textAlign:'left'}} data-reveal-delay="400">
              In 2016, the geoJSON specification became more strict and many old geoJSONs will not work in new tools like Mapbox, or throw errors. GeoJSONLint has not updated and many of its polygons are broken. It’s important to “rewind” your geoJSONs so that they fit with this more tight format.
              </p>
              Paste your geoJSON below:
              <textarea id="converter" className="big-textarea" onChange={(e) => console.log(e)} placeholder={'{\n  "type" : "FeatureCollection",\n  "features" : [] \n}'}>

              </textarea>
              <Button onClick={() => convert()}>See Conversion</Button>
              <textarea id="conversion" className="big-textarea" />
              <div style={{textAlign: 'left'}}>
                <h3>What is the Right Hand Rule?</h3>
                <p>When you construct a polygon, you can order the coordinates in one direction or another. If you’re drawing a circle, you might start on the left and go counter-clockwise around to meet the original point. Or, you might go clockwise.</p>
                <p>Here is the specification:</p>
                <p><em>A linear ring MUST follow the right-hand rule with respect to the area it bounds, i.e., exterior rings are counterclockwise, and holes are clockwise.</em></p>
                <p>While the specification states that parsers should not break on finding this error, many do, such as Mapbox’s Dataset Uploading tool. Mapbox will export GeoJSONs with the wrong format, but won’t accept them, which is frustrating. Informally, this specification can be ignored, but practically it’s becoming normal to ensure your polygons match.</p>
                <p>Please get in touch with Mapster if you run into any problems with this tool.</p>
              </div>
              </div>
          </div>
        </div>
      </div>
    </section>
  );
}

Hero.propTypes = propTypes;
Hero.defaultProps = defaultProps;

export default Hero;
